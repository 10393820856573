<template>
    <div>
        <div class="container" style="background-color:#fff">
            <div class="form row d-flex my-5 py-5 mx-0">
                <h3 class="text-center fst-italic" style="background-color: #fcece1">{{ helpData.sub_title }}</h3>
                <div class="col-12 text-center my-4">
                    <h2 class="fw-bold">{{ helpData.title }}</h2>
                </div>
                <div class="col-lg-6 col-md-8 col-sm-10 col-12 px-3 m-auto rounded">
                    <div class="no-gutters br-2">
                        <img src="../../assets/images/search.svg" alt="img-fluid">
                        <input  type="text" data-bs-toggle="modal" data-bs-target="#searchleModal" class="form-control search-bar ps-5" placeholder="Search">
                    </div>
                </div>
            </div>
            <div class="container">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active me-5" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#business" type="button" role="tab" aria-controls="tab1" aria-selected="true">Businesses</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link ms-5" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#client" type="button" role="tab" aria-controls="tab2" aria-selected="false">Clients</button>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="business" role="tabpanel" aria-labelledby="tab1-tab">
                    <div class="row my-5">
                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample1">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Do you have a subscription plan?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body">
                                            Yes, our plan fits any size of business, we have a monthly subscription plan and Pay As You Earn.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Do you have a free trial?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            You will get a free trial when you sign up; no credit cards are needed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Do you have mobile applications?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes we do, although we will first launch Ondaq web application, then later launch the mobile application.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>How do I get more clients?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Ondaq analysis data, we use the result to target the right audience. And you will get free email campaign tool.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>I live in New York, will Ondaq work here?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, Ondaq is an online platform and a marketplace. We will be launching in Charlotte, North Carolina.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Is this company an American company?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, Ondaq was founded in Charlotte, North Carolina
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>How do I reach OnDaQ support? </strong>
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Our support team can be reached via email at support@ondaq.com or via chat when it’s available
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-8" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Do I need to confirm booking manually? </strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-8" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            No, when a client books an appointment, the professional or business will receive a text and an email about the appointment. Automatically the order will seen in the calendar slot.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-9" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>How does standard booking work?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-9" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            This feature allows professionals/businesses to block their schedule for the full duration of a particular appointment. For example: A client has scheduled a 60-minute service starting at 11:00 AM. If the service is classified as a standard booking then the professional’s calendar will show that he/she does not have availability for 60 minutes starting at 11:00 AM and ending at 12:00 PM.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-10" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>What is flex booking? </strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-10" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            This feature allows professionals/businesses to show availability even though they are already booked for a particular service with a client. When entering a service, flex booking should be selected if the professional will not be attending to a client for the entire duration of a service. For example, if a client books a 50-minute wash and deep conditioning treatment, there may be an initial wash time of 5 minutes then a deep conditioning time of 30 minutes and a finish time 15 minutes. The 30 minutes that the client is under the dryer for the deep conditioning treatment could be classified as “Flex” time. This 30-minute availability will be shown on the professional’s calendar.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-11" aria-expanded="false" aria-controls="collapseOne">
                                            <strong> How can I apply discounted price?  </strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-11" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            This feature allows professionals/businesses to apply a discount to the regular cost of a particular service by inputting a reduced price. The client will be able to see the previous price as well as the new one so that they are aware of the discount.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-12" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>What are package deals?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-12" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            This feature allows professionals/businesses to bundle two or three services and offer them at a discount. For example; a manicure costs $20 and a pedicure costs $35. The professional/business can create a Manicure/Pedicure Package Deal and offer it to clients for $50. The client would be saving $5 with the package because if he/she booked the services individually, the cost would be $55.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-13" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Why is package deal image important? </strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-13" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Professionals/businesses are required to upload an image that corresponds to the listed package deal. This image will be displayed to the client for that particular package. The package deal image could either be the logo of the professional/business or a picture related to the services that will be provided.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-14" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Email Campaigns <i class="text-danger">(Launching soon) </i> </strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-14" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            This feature allows professionals/businesses to send communications to clients who have reserved services with them through the OnDaQ platform. These email campaigns may be used by the professionals/businesses to send advertisements or announcements to their clientele.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-15" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Learn about services </strong>
                                        </button>
                                    </h2>
                                    <div id="collapse-15" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            This feature allows professionals/businesses to define services that are being offered to clients. In order to list a service, the following details need to be known: Title, Description, Price and Duration. All services will be entered as a Standard Booking unless Flex Booking is selected. If the service is being offered at a discount, then the professional/business would be required to enter the Discounted Price when adding the service.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="tab-pane fade" id="client" role="tabpanel" aria-labelledby="tab2-tab">
                    <div class="row my-5">
                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1C" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>How long does it take to signup?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapse1C" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            It takes less than 60 seconds (1 minute) to sign up for your account.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2C" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Do I need to pay before using the platform?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapse2C" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Absolutely not, OnDaQ marketplace is totally free to use, however, payments will be processed during your service or after completion of service.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3C" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>What should do if I need help?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapse3C" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            You can send our support team and email at support@ondaq.com
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="col-lg-6 col-sm-12 mb-3">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item border-0">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4C" aria-expanded="false" aria-controls="collapseOne">
                                            <strong>Do you have videos to show me how it works?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapse4C" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes we have videos to guide you throughout the process. Go to Ondaq channel.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">Tab 1</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">Tab 2</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12 mb-3">
                                        <div class="accordion" id="accordionExample1">
                                            <div class="accordion-item border-0">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <strong>Do you have a subscription plan?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                    <div class="accordion-body">
                                                        Yes, our plan fits any size of business, we have a monthly subscription plan and Pay As You Earn.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12 mb-3">
                                        <div class="accordion" id="accordionExample1">
                                            <div class="accordion-item border-0">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <strong>Do you have a subscription plan?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                    <div class="accordion-body">
                                                        Yes, our plan fits any size of business, we have a monthly subscription plan and Pay As You Earn.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 mb-3">
                                        <div class="accordion" id="accordionExample1">
                                            <div class="accordion-item border-0">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <strong>Do you have a subscription plan?</strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                    <div class="accordion-body">
                                                        Yes, our plan fits any size of business, we have a monthly subscription plan and Pay As You Earn.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                helpData:[],
                bannerImage: ''
            }
        },
        methods:{
            bannerBgImage(img){
                this.bannerImage = 'background-image: url("'+img+'")'
            },
            bannerData(){
                let thiss = this
                axios.get('bannerData/Help').then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.helpData = response.data.data[0]
                        console.log(thiss.helpData)
                        thiss.bannerBgImage(thiss.helpData.banner_image)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            thiss.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.toast.error(error.response.data.message);
                        }
                    }
                });
            }
        },
        mounted() {
            this.bannerData()
            //inti tooltip
            // Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            // .forEach(tooltipNode => new Tooltip(tooltipNode).enable())
        }
    }
</script>

<style scoped>
    .FAQbanner{
        padding: 80px 0px;
    }
    .container img{
        width: 20px;
        position: relative;
        top: 33px;
        left: 15px;
    }
    .nav-pills{
        --bs-nav-pills-link-active-bg: purple;
    }
    .nav-pills .nav-link.active{
        color: black ;
        font-size: 20px ;
        background: #fff;
        font-weight: 700;
    }
    .bottom-line{
        border-bottom: 5px solid purple;
        border-radius: 5px;
    }
</style>